export function debounce<T extends (...args: any[]) => any>(
  callback: T,
  wait: number
): () => void {
  let timeoutId: NodeJS.Timeout | null

  return (...args: Parameters<T>) => {
    clearTimeout(timeoutId!)
    timeoutId = setTimeout(() => callback(...args), wait)
  }
}

export function exposureTimeDisplay(value: string) {
  return Number(value) >= 1
    ? `${value} seconds`
    : `1/${1 / Number(value)} seconds`
}

export function fNumberDisplay(value: string) {
  return `f${value}`
}

export function datetimeDisplay(value: string) {
  return new Date(Number(value) * 1000).toLocaleString()
}
