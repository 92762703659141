import { Form, Formik } from 'formik'
import { useState } from 'react'
import {
  SelectDetailField,
  SelectDetailFieldOption,
  TextDetailField,
} from '../../../utilities/DetailField'
import {
  Alert,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Typography,
} from '@mui/material'
import { DetailProps } from '../../../utilities/CRUDComponents'
import { Photo } from 'types'

const selectOptions: { [key: string]: SelectDetailFieldOption[] } = {
  publishedStatus: [
    { label: 'Draft', value: 'DRAFT' },
    { label: 'Published', value: 'PUBLISHED' },
  ],
}

export function PhotoManagementDetail(props: DetailProps<Photo>) {
  const [editMode, setEditMode] = useState<boolean>(false)
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false)

  if (props.loading) {
    return (
      <Card>
        <CardContent>
          <CircularProgress />
        </CardContent>
      </Card>
    )
  }

  return (
    <Card>
      <Formik
        initialValues={props.item ?? ({} as Photo)}
        onSubmit={async (values, { setSubmitting }) => {
          setSubmitting(true)
          const result = await props.onSubmit(values)
          setSubmitting(false)

          if (result) {
            setEditMode(false)
          }
        }}
        validate={async photoMetadata => {
          // Formik recommends a declarative template for validation, but since we only have a few fields inlining the logic will suffice.
          const errors: { [key: string]: string } = {}

          const mandatoryKeys: (keyof Photo)[] = [
            'title',
            'fNumber',
            'exposureTime',
            'iso',
            'focalLength',
            'bodyModel',
            'lensModel',
            'publishedStatus',
          ]

          for (const key of mandatoryKeys) {
            if (photoMetadata[key] === '' || photoMetadata[key] === undefined) {
              errors[key] = 'Field cannot be empty.'
            }
          }
          return errors
        }}
      >
        {({ isSubmitting, isValid, setValues }) => (
          <>
            <CardActions>
              <Button onClick={props.onClose}>Close</Button>
              <Button
                onClick={() => {
                  if (editMode && props.item) {
                    setValues(props.item)
                  }
                  setEditMode(!editMode)
                }}
              >
                {editMode ? 'Reset' : 'Edit'}
              </Button>
              <Button onClick={() => setShowDeleteModal(true)}>Delete</Button>
            </CardActions>
            <Divider />

            <CardContent>
              {props.detailBanner && (
                <Alert
                  severity={props.detailBanner.isSuccess ? 'success' : 'error'}
                  sx={{ marginBottom: '10px' }}
                >
                  {props.detailBanner.content}
                </Alert>
              )}
              <Typography gutterBottom variant="h5" component="div">
                {props.item?.title}
              </Typography>

              <Box style={{ display: 'flex' }}>
                <Box style={{ width: '300px' }}>
                  <Form>
                    {editMode && (
                      <TextDetailField
                        editMode={editMode}
                        label="Title"
                        name="title"
                      />
                    )}
                    <TextDetailField
                      editMode={editMode}
                      label="Description"
                      name="description"
                      multline
                    />
                    <TextDetailField
                      editMode={editMode}
                      label="F Number"
                      name="fNumber"
                    />
                    <TextDetailField
                      editMode={editMode}
                      label="Exposure"
                      name="exposureTime"
                    />
                    <TextDetailField
                      editMode={editMode}
                      label="ISO"
                      name="iso"
                    />
                    <TextDetailField
                      editMode={editMode}
                      label="Focal Length"
                      name="focalLength"
                    />
                    <TextDetailField
                      editMode={editMode}
                      label="Photo Datetime"
                      name="dateTimeOriginal"
                    />
                    <TextDetailField
                      editMode={editMode}
                      label="Body Model"
                      name="bodyModel"
                    />
                    <TextDetailField
                      editMode={editMode}
                      label="Lens Model"
                      name="lensModel"
                    />
                    <SelectDetailField
                      editMode={editMode}
                      label="Published Status"
                      name="publishedStatus"
                      options={selectOptions.publishedStatus}
                    />
                    <TextDetailField
                      editMode={editMode}
                      label="Update Time"
                      name="dateTimeOriginal"
                    />

                    {editMode && (
                      <Box style={{ display: 'flex', marginTop: '10px' }}>
                        <Button
                          disabled={isSubmitting || !isValid || props.loading}
                          type="submit"
                        >
                          Update
                        </Button>
                        {isSubmitting && <CircularProgress />}
                      </Box>
                    )}
                  </Form>
                </Box>
                <Box sx={{ margin: '0 15px' }}>
                  <Divider
                    orientation="vertical"
                    sx={{ opacity: 1, backgroundColor: 'grey' }}
                  />
                </Box>

                <img
                  src={`https://jasonandfriends-beta-pictures-bucket.s3.amazonaws.com/processed/${props.item?.entryId}/fullsize_${props.item?.entryId}`}
                  alt={props.item?.title}
                  style={{
                    maxWidth: 'calc(100% - 300px)',
                    height: 'auto',
                  }}
                />
              </Box>
            </CardContent>
            <Dialog
              open={showDeleteModal}
              onClose={() => setShowDeleteModal(false)}
              aria-labelledby="delete-photo-dialog-title"
              aria-describedby="delete-photo-dialog-description"
            >
              <DialogTitle id="delete-photo-dialog-title">
                Delete Photo?
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="delete-photo-dialog-description">
                  Are you sure you want to delete this photo? This action is
                  permanent and cannot be undone.
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={() => setShowDeleteModal(false)}>
                  Cancel
                </Button>
                <Button
                  onClick={() => props.onDelete(props.item as Photo)}
                  autoFocus
                >
                  Delete
                </Button>
              </DialogActions>
            </Dialog>
          </>
        )}
      </Formik>
    </Card>
  )
}
