import React from 'react'

type WindowSize = {
  innerHeight: number | null
  innerWidth: number | null
}

function getWindowSize(): WindowSize {
  return {
    innerHeight: window.innerHeight,
    innerWidth: window.innerWidth,
  }
}

export function useWindowSize(): WindowSize {
  const [windowSize, setWindowSize] = React.useState({
    innerHeight: null,
    innerWidth: null,
  })

  function handleResize() {
    // @ts-expect-error Argument of type 'WindowSize' is not assignable to parameter of type 'SetStateAction<{ inner...
    setWindowSize(getWindowSize())
  }

  React.useEffect(() => {
    // @ts-expect-error Argument of type 'WindowSize' is not assignable to parameter of type 'SetStateAction<{ inner...
    setWindowSize(getWindowSize())

    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
    // NOTE(alan.duan): window does not change
  }, [])

  return windowSize
}
