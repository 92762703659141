"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.globalConfig = void 0;
exports.globalConfig = {
    local: {
        apiPort: 8000,
    },
    pipeline: {
        account: '741740907498',
        region: 'us-east-1',
    },
    repo: {
        secretManagerToken: 'Github',
        provider: 'gitHub',
        account: 'jasonou1994',
        repository: 'jasonandfriends-2022',
        branch: 'master',
    },
    hostedZone: {
        hostedZoneName: 'jasonou.link',
        hostedZoneId: 'Z07130151UJK1OI2S14J1',
    },
};
