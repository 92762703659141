import { DataGrid, GridColDef } from '@mui/x-data-grid'
import { useMemo, useState } from 'react'
import { Photo } from 'types'
import {
  datetimeDisplay,
  exposureTimeDisplay,
  fNumberDisplay,
} from '../../../utilities/utils'
import { TableProps } from '../../../utilities/CRUDComponents'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material'
import { LoadingButton } from '@mui/lab'

const columns: GridColDef[] = [
  {
    field: '_entryId', // Unique key for the row
    headerName: 'Preview',
    width: 135,
    renderCell: params => (
      <img
        src={`https://jasonandfriends-beta-pictures-bucket.s3.amazonaws.com/processed/${params.row.entryId}/thumbnail_${params.row.entryId}`}
        alt={params.row.entryId}
        style={{ height: '100%' }}
      />
    ),
  },
  { field: 'entryId', headerName: 'ID', flex: 2 },
  { field: 'title', headerName: 'Title', flex: 2 },
  { field: 'uploadStatus', headerName: 'Upload Status', flex: 1 },
  { field: 'publishedStatus', headerName: 'Published Status', flex: 1 },
  { field: 'iso', headerName: 'ISO', flex: 1 },
  {
    field: 'fNumber',
    headerName: 'F Number',
    flex: 1,
    valueGetter: fNumberDisplay,
  },
  {
    field: 'exposureTime',
    headerName: 'Exposure Time',
    flex: 1,
    valueGetter: exposureTimeDisplay,
  },
  { field: 'focalLength', headerName: 'Focal Length', flex: 1 },
  { field: 'bodyModel', headerName: 'Body Model', flex: 1 },
  { field: 'lensModel', headerName: 'Lens Model', flex: 1 },
  {
    field: 'dateTimeOriginal',
    headerName: 'Capture Time',
    flex: 1.5,
    valueGetter: datetimeDisplay,
  },

  {
    field: 'uploadTime',
    headerName: 'Upload Time',
    flex: 1,
    valueGetter: datetimeDisplay,
  },
]

export function PhotoManagementTable(props: TableProps<Photo>) {
  const rows = useMemo(
    () =>
      props.data.map(photo => ({
        ...photo,
        id: photo.entryId,
      })),
    [props.data]
  )
  const [selectedRows, setSelectedRows] = useState<string[]>([])
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false)

  return (
    <section style={{ height: '100%' }}>
      <Box sx={{ marginBottom: '8px' }}>
        <LoadingButton loading={props.loading} onClick={props.onRefresh}>
          Refresh
        </LoadingButton>
        {selectedRows.length > 0 && (
          <LoadingButton
            onClick={() => setShowDeleteModal(true)}
            sx={{ marginLeft: '4px' }}
            loading={props.loading}
          >
            Delete
          </LoadingButton>
        )}
      </Box>

      <DataGrid
        rowHeight={75}
        onRowClick={row => props.onRowClick(row.row as Photo)}
        rows={rows}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 100,
            },
          },
        }}
        pageSizeOptions={[100]}
        checkboxSelection
        disableRowSelectionOnClick
        onRowSelectionModelChange={newSelection =>
          setSelectedRows(newSelection as string[])
        }
        rowSelectionModel={selectedRows}
        sx={{
          // pointer cursor on ALL rows
          '& .MuiDataGrid-row:hover': {
            cursor: 'pointer',
          },
        }}
      />

      <Dialog
        open={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
        aria-labelledby="delete-photos-dialog-title"
        aria-describedby="delete-photos-dialog-description"
      >
        <DialogTitle id="delete-photos-dialog-title">
          Delete Photos?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="delete-photos-dialog-description">
            Are you sure you want to delete the selected photos? This action is
            permanent and cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowDeleteModal(false)}>Cancel</Button>
          <Button
            onClick={() => {
              props.onDeleteRows(
                selectedRows.map(
                  row =>
                    ({
                      entryId: row,
                    } as Photo)
                )
              )
              setShowDeleteModal(false)
            }}
            autoFocus
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </section>
  )
}
