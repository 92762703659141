import { currentStage } from 'config'
import { parseDomain, ParseResultType } from 'parse-domain'
import { Page, Photo } from 'types'
import { logger } from 'utilities'

export const getApiDomain = () => {
  return (
    process.env.REACT_APP_SERVICE_API ||
    (() => {
      const { host, protocol } = window.location

      const parseResult = parseDomain(host)

      if (parseResult.type === ParseResultType.Listed) {
        const { domain, topLevelDomains } = parseResult

        const consolidatedTopLevelDomain = topLevelDomains.join('.')

        const result = `${protocol}//${currentStage}.service.${domain}.${consolidatedTopLevelDomain}`

        logger.info('API domain:', result)
      } else {
        console.warn(parseResult)
        throw new Error('Domain cannot be parsed from current location.')
      }
    })()
  )
}
export const fetchAllPhotos = async (): Promise<Photo[]> => {
  await new Promise(resolve => setTimeout(resolve, 1000))
  const result = await fetch(`${getApiDomain()}/photos`)
  const json = await result.json()
  return json.photos
}

export const fetchPhoto = async (photoId: string): Promise<Photo> => {
  await new Promise(resolve => setTimeout(resolve, 1000))
  const result = await fetch(`${getApiDomain()}/photos/${photoId}`)
  return await result.json()
}

export const updatePhoto = async (photo: Photo) => {
  const result = await fetch(`${getApiDomain()}/photos/${photo.entryId}`, {
    method: 'POST',
    body: JSON.stringify(photo),
    headers: {
      'Content-Type': 'application/json',
    },
  })
  return await result.json()
}

const getUploadUrl = async (name: string) => {
  const result = await fetch(`${getApiDomain()}/photos/upload_uri?name=${name}`)
  const json = await result.json()
  return json.uploadUrl
}

export const uploadPhoto = async (name: string, file: File) => {
  const uploadUrl = await getUploadUrl(name)
  await fetch(uploadUrl, {
    method: 'PUT',
    body: file,
  })
}

export const deletePhoto = async (photoId: string): Promise<void> => {
  await fetch(`${getApiDomain()}/photos/${photoId}`, {
    method: 'DELETE',
  })
}

export const deletePhotos = async (photoIds: string[]): Promise<void> => {
  await fetch(`${getApiDomain()}/photos`, {
    method: 'DELETE',
    body: JSON.stringify({ photoIds }),
    headers: {
      'Content-Type': 'application/json',
    },
  })
}

export const fetchAllPages = async (): Promise<Page[]> => {
  await new Promise(resolve => setTimeout(resolve, 1000))
  const result = await fetch(`${getApiDomain()}/pages`)
  const json = await result.json()
  return json.pages
}

export const fetchPage = async (pageId: string): Promise<Page> => {
  await new Promise(resolve => setTimeout(resolve, 1000))
  const result = await fetch(`${getApiDomain()}/pages/${pageId}`)
  return await result.json()
}

export const updatePage = async (page: Page) => {
  const result = await fetch(`${getApiDomain()}/pages/${page.entryId}`, {
    method: 'POST',
    body: JSON.stringify(page),
    headers: {
      'Content-Type': 'application/json',
    },
  })
  return await result.json()
}
export const deletePage = async (page: Page): Promise<void> => {
  await fetch(`${getApiDomain()}/photos/${page.entryId}`, {
    method: 'DELETE',
  })
}

export const deletePages = async (pageIds: string[]): Promise<void> => {
  await fetch(`${getApiDomain()}/pages`, {
    method: 'DELETE',
    body: JSON.stringify({ pageIds }),
    headers: {
      'Content-Type': 'application/json',
    },
  })
}
