import { useMemo, useRef, useState } from 'react'
import { Photo, PhotoUploadStatus } from 'types'
import { useBanner } from '../BannerProvider'
import { deletePhotos, fetchAllPhotos, uploadPhoto } from '../../utilities/api'
import { debounce } from '../../utilities/utils'
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Chip,
  FormControlLabel,
  LinearProgress,
  Switch,
  Typography,
} from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { VerticalFlexGallery } from '../../utilities/VerticalFlexGallery'

interface PhotoUpload {
  image: string // this is the image "src"
  photo: Photo // the metadata
  file: File // the binary file

  isDuplicate: boolean
  shouldUpload: boolean // Used for photo uploads where the file already exists in the database
  errorReason?: string
}

type PhotoUploadMap = Map<string, PhotoUpload>

export const UploadManager = () => {
  const [uploads, setUploads] = useState<PhotoUploadMap>(
    new Map<string, PhotoUpload>()
  )
  const [uploadInProgress, setUploadInProgress] = useState(false)
  const [fileSelectionInProgress, setFileSelectionInProgress] = useState(false)

  const { showBanner } = useBanner()
  const failedUploads = useRef(new Set<string>())

  const updateUpload = (upload: PhotoUpload) => {
    setUploads(prevUploads => {
      const newUploads = new Map<string, PhotoUpload>(prevUploads)
      newUploads.set(upload.photo.entryId, upload)
      return newUploads
    })
  }
  const removeUpload = (entryId: string) =>
    setUploads(prevUploads => {
      const newUploads = new Map<string, PhotoUpload>(prevUploads)
      newUploads.delete(entryId)
      return newUploads
    })

  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const fileList = event.target.files
    if (!fileList) return

    const allPhotos = await fetchAllPhotos()
    const allPhotoEntryIds = new Set(allPhotos.map(photo => photo.entryId))

    try {
      setFileSelectionInProgress(true)
      await Promise.all(
        Array.from(fileList).map(async file => {
          const image = await new Promise<string>((resolve, reject) => {
            // We need to read the file as a data URL to display it in the UI
            const reader = new FileReader()
            reader.onload = () => {
              resolve(reader.result as string)
            }
            reader.onerror = reject
            reader.readAsDataURL(file)
          })

          const photoUpload = {
            image,
            photo: {
              entryId: file.name,
              title: file.name,
              uploadStatus: 'STARTED',
            } as Photo,
            file,
            isDuplicate: allPhotoEntryIds.has(file.name),
            shouldUpload: !allPhotoEntryIds.has(file.name), // default duplicates to no, ask user to confirm overwrite or not
          }
          updateUpload(photoUpload)
        })
      )
    } catch (e) {
      showBanner('Error reading files: ' + (e as Error).message, 'error')
      setUploads(new Map<string, PhotoUpload>())
    } finally {
      setFileSelectionInProgress(false)
    }
  }

  const uploadFiles = async () => {
    const filesToBeUploaded = [...uploads.values()].filter(
      upload => upload.photo.uploadStatus === 'STARTED' && upload.shouldUpload
    )
    if (filesToBeUploaded.length === 0) {
      return
    }

    // delete all the photos that are duplicates and should be overwritten, since we are going to simultaneously pull down the state of the photos periodically, and we don't want to pull down old metadata
    const duplicatesToBeDeleted = [...uploads.values()].filter(
      upload => upload.isDuplicate && upload.shouldUpload
    )

    try {
      await deletePhotos(
        duplicatesToBeDeleted.map(upload => upload.photo.entryId)
      )
    } catch (e) {
      showBanner('Error deleting duplicates', 'error')
      return
    }

    failedUploads.current = new Set<string>()
    try {
      setUploadInProgress(true)
      const uploadPromises = Promise.allSettled(
        filesToBeUploaded.map(async upload => {
          try {
            await uploadPhoto(upload.photo.title as string, upload.file)
            updateUpload({
              ...upload,
              photo: { ...upload.photo, uploadStatus: 'UPLOADED' },
            })
          } catch (error) {
            updateUpload({
              ...upload,
              errorReason: (error as Error).message,
              shouldUpload: false,
              photo: { ...upload.photo, uploadStatus: 'UPLOAD_FAILED' },
            })
            failedUploads.current.add(upload.photo.entryId)

            showBanner(
              `Error uploading photo: ${upload.photo.entryId}. Remaining photos will continue to upload.`,
              'error'
            )
            throw error
          }
        })
      )
      // This interval is responsible for fetching the latest state of the photos from the database, since processing happens asynchronously.
      // Lasts for 30 seconds, or if all uploads have failed or succeeded.
      // In order to prevent multiple uploads, loading state is set to false only when all uploads have failed or succeeded, or if the interval times out
      const updateInterval = setInterval(async () => {
        if (failedUploads.current.size === filesToBeUploaded.length) {
          clearInterval(updateInterval)
          clearInterval(processingTimeout)
          setUploadInProgress(false)
          return
        }

        const dbPhotos = (await fetchAllPhotos()).filter(photo =>
          filesToBeUploaded.some(
            upload => upload.photo.entryId === photo.entryId
          )
        )
        // Because this interval is launched immediately, there is a high chance that the fetchAllPhotos call will return before the first upload is processed.
        if (dbPhotos.length === 0) return

        filesToBeUploaded.forEach((upload, i) => {
          const dbPhoto = dbPhotos.find(
            photo => photo.entryId === upload.photo.entryId
          )

          if (dbPhoto) {
            updateUpload({
              ...upload,
              photo: { ...upload.photo, uploadStatus: dbPhoto.uploadStatus },
            })
          }
        })

        if (
          dbPhotos.every(
            photo =>
              photo.uploadStatus === 'COMPLETE' ||
              // Need to use a ref here because the state is not updated yet
              failedUploads.current.has(photo.entryId)
          )
        ) {
          clearInterval(updateInterval)
          clearInterval(processingTimeout)
          setUploadInProgress(false)
        }
      }, 1000)
      const processingTimeout = setTimeout(() => {
        clearInterval(updateInterval)
        showBanner(
          'Processing is taking longer than expected. Please check back later.',
          'error'
        )
        // Set all remaining uploads to TIMEDOUT
        filesToBeUploaded
          .filter(
            upload =>
              upload.photo.uploadStatus !== 'COMPLETE' &&
              upload.photo.uploadStatus !== 'UPLOAD_FAILED'
          )
          .forEach(upload => {
            updateUpload({
              ...upload,
              photo: { ...upload.photo, uploadStatus: 'UPLOAD_TIMED_OUT' },
            })
          })
        setUploadInProgress(false)
      }, 30000)
      await uploadPromises
    } catch (error) {
      showBanner('Error uploading photos: ' + (error as Error).message, 'error')
    }
  }

  const onShouldUploadToggle = (upload: PhotoUpload) => {
    updateUpload({
      ...upload,
      shouldUpload: !upload.shouldUpload,
    })
  }

  return (
    <UploadContainer
      uploads={uploads}
      onFileChange={handleFileChange}
      uploadFiles={uploadFiles}
      onShouldUploadToggle={onShouldUploadToggle}
      uploadInProgress={uploadInProgress}
      removeUpload={removeUpload}
      fileSelectionInProgress={fileSelectionInProgress}
    />
  )
}

export const UploadContainer = ({
  uploads,
  onFileChange,
  removeUpload,
  uploadFiles,
  onShouldUploadToggle,
  uploadInProgress,
  fileSelectionInProgress,
}: {
  uploads: PhotoUploadMap
  onFileChange: (event: React.ChangeEvent<HTMLInputElement>) => Promise<void>
  removeUpload: (entryId: string) => void
  uploadFiles: () => Promise<void>
  onShouldUploadToggle: (upload: PhotoUpload) => void
  uploadInProgress: boolean
  fileSelectionInProgress: boolean
}) => {
  const { totalUploads, totalDuplicates, totalDuplicatesToBeOverwritten } =
    useMemo(
      () =>
        [...uploads.values()]
          .filter(upload => upload.photo.uploadStatus !== 'COMPLETE')
          .reduce(
            (acc, upload) => {
              acc.totalUploads += 1
              if (upload.isDuplicate) {
                acc.totalDuplicates += 1
                if (upload.shouldUpload) {
                  acc.totalDuplicatesToBeOverwritten += 1
                }
              }
              return acc
            },
            {
              totalUploads: 0,
              totalDuplicates: 0,
              totalDuplicatesToBeOverwritten: 0,
            }
          ),
      [uploads]
    )

  return (
    <section style={{ marginTop: '10px' }}>
      <Box sx={{ marginBottom: '8px' }}>
        <LoadingButton
          component="label"
          loading={uploadInProgress || fileSelectionInProgress}
        >
          Choose Photos
          <input type="file" hidden onChange={onFileChange} multiple />
        </LoadingButton>
        {uploads.size > 0 && (
          <LoadingButton
            component="label"
            variant="contained"
            loading={uploadInProgress || fileSelectionInProgress}
            onClick={debounce(uploadFiles, 200)}
            disabled={
              !totalUploads ||
              (totalUploads === totalDuplicates &&
                totalDuplicatesToBeOverwritten === 0)
            }
          >
            Upload
          </LoadingButton>
        )}
      </Box>

      {uploads.size > 0 && (
        <Box sx={{ marginBottom: '16px' }}>
          <Chip
            label={`${totalUploads} files selected`}
            sx={{ marginRight: '8px' }}
          />
          <Chip
            label={`${totalDuplicates} duplicates selected`}
            sx={{ marginRight: '8px' }}
          />
          <Chip
            label={`${totalDuplicatesToBeOverwritten} duplicates to be overwritten`}
          />
        </Box>
      )}

      <VerticalFlexGallery
        columnWidth={300}
        components={[...uploads.values()].map(upload => (
          <Upload
            key={upload.photo.entryId}
            upload={upload}
            removeUpload={() => removeUpload(upload.photo.entryId)}
            onShouldUploadToggle={() => onShouldUploadToggle(upload)}
            uploadInProgress={uploadInProgress}
          />
        ))}
      />
    </section>
  )
}

export const Upload = (props: {
  upload: PhotoUpload
  onShouldUploadToggle: () => void
  removeUpload: () => void
  uploadInProgress: boolean
}) => {
  const backgroundColorMapping = {
    STARTED: 'white',
    UPLOADED: 'white',
    UPLOAD_FAILED: 'lightcoral',
    UPLOAD_TIMED_OUT: 'lightcoral',
    PENDING: 'white',
    EXIF_PARSED: 'white',
    RESIZING_FINISHED: 'white',
    PROCESSING_FAILED: 'lightcoral',
    COMPLETE: 'lightgreen',
  }

  return (
    <Card
      sx={{
        backgroundColor:
          backgroundColorMapping[props.upload.photo.uploadStatus],
      }}
    >
      <CardContent>
        <Typography sx={{ fontSize: 16 }} gutterBottom>
          {props.upload.photo.title}
        </Typography>
        {props.uploadInProgress && props.upload.shouldUpload && (
          <UploadProgress status={props.upload.photo.uploadStatus} />
        )}

        {props.upload.isDuplicate &&
          props.upload.photo.uploadStatus !== 'COMPLETE' && (
            <FormControlLabel
              control={
                <Switch
                  disabled={props.uploadInProgress}
                  checked={props.upload.shouldUpload}
                  onChange={props.onShouldUploadToggle}
                />
              }
              label="Overwrite existing photo"
            />
          )}
      </CardContent>
      <img
        src={props.upload.image}
        alt={`Uploaded file ${props.upload.photo.title}`}
        style={{ width: '100%', height: 'auto' }}
      />

      {!props.uploadInProgress &&
        props.upload.photo.uploadStatus === 'STARTED' && (
          <CardActions>
            <Button size="small" color="primary" onClick={props.removeUpload}>
              Remove
            </Button>
          </CardActions>
        )}
    </Card>
  )
}

function UploadProgress({ status }: { status: PhotoUploadStatus }) {
  let value = 0
  switch (status) {
    case 'STARTED':
      value = 16.66
      break
    case 'UPLOADED':
      value = 33.32
      break
    case 'UPLOAD_FAILED':
      value = 33.32
      break
    case 'UPLOAD_TIMED_OUT':
      value = 33.32
      break
    case 'PENDING':
      value = 50
      break
    case 'EXIF_PARSED':
      value = 66.66
      break
    case 'RESIZING_FINISHED':
      value = 83.33
      break
    case 'PROCESSING_FAILED':
      value = 83.33
      break
    case 'COMPLETE':
      value = 100
      break
  }

  let statusText = ''
  switch (status) {
    case 'STARTED':
      statusText = 'Upload Started'
      break
    case 'UPLOADED':
      statusText = 'Uploaded'
      break
    case 'UPLOAD_FAILED':
      statusText = 'Upload Failed'
      break
    case 'UPLOAD_TIMED_OUT':
      statusText = 'Upload Timed Out'
      break
    case 'PENDING':
      statusText = 'Pending'
      break
    case 'EXIF_PARSED':
      statusText = 'EXIF Parsed'
      break
    case 'RESIZING_FINISHED':
      statusText = 'Resizing Finished'
      break
    case 'PROCESSING_FAILED':
      statusText = 'Processing Failed'
      break
    case 'COMPLETE':
      statusText = 'Complete'
      break
  }

  return (
    <Box>
      <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
        {statusText}
      </Typography>
      <LinearProgress variant="determinate" value={value} />
    </Box>
  )
}
