import React, { useEffect, useState, VoidFunctionComponent } from 'react'
import { Photo } from 'types'
import { getApiDomain } from '../utilities/api'

export const Gallery: VoidFunctionComponent = () => {
  const [photos, setPhotos] = useState<Photo[]>([])

  const fetchPhotos = async () => {
    const result = await fetch(`${getApiDomain()}/photos`)
    const json = await result.json()
    setPhotos(json.photos)
  }

  useEffect(() => {
    fetchPhotos()
  }, [])

  console.log({ photos })

  return (
    <section>
      {photos.map(p => (
        <div>
          <h5>{p.title}</h5>
          <h5>{p.description}</h5>
          {/* <img src={p.url} alt={p.description} /> */}
        </div>
      ))}
    </section>
  )
}
