import React, { VoidFunctionComponent } from 'react'

export const Header: VoidFunctionComponent = () => {
  return (
    <header>
      <h1>testtesttest</h1>
      <h2>Portfolio & Photography</h2>
    </header>
  )
}
