import { Alert } from '@mui/material'
import { v4 as uuidv4 } from 'uuid'
import React, { createContext, useContext, useState } from 'react'

// Create a context for the banner messages
const BannerContext = createContext<{
  showBanner: (
    message: string,
    severity: AlertProps['severity'],
    dismissTime?: number
  ) => void
}>({
  showBanner: () => {},
})

// Custom hook to access the banner context
const useBanner = () => useContext(BannerContext)

interface AlertProps {
  severity: 'error' | 'warning' | 'info' | 'success'
  message: string
  id: string
}

// Banner provider component
const BannerProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [messages, setmessages] = useState<AlertProps[]>([])

  const dismissBanner = (id: string) => {
    setmessages(prevmessages => prevmessages.filter(msg => msg.id !== id))
  }

  const showBanner = (
    message: string,
    severity: AlertProps['severity'] = 'info',
    dismissTime?: number
  ) => {
    const id = uuidv4()
    setmessages(prevmessages => [
      ...prevmessages,
      { severity, message: message, id },
    ])

    if (dismissTime) {
      setTimeout(() => dismissBanner(id), dismissTime)
    }
  }

  return (
    <>
      {messages.map(message => (
        <Alert
          severity={message.severity}
          onClose={() => dismissBanner(message.id)}
          sx={{ marginBottom: '10px' }}
          key={message.id}
        >
          {message.message}
        </Alert>
      ))}
      <BannerContext.Provider value={{ showBanner }}>
        {children}
      </BannerContext.Provider>
    </>
  )
}

export { BannerProvider, useBanner }
