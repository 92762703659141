import { Form, Formik } from 'formik'
import { useState } from 'react'
import {
  SelectDetailField,
  SelectDetailFieldOption,
  TextDetailField,
} from '../../../utilities/DetailField'
import {
  Alert,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Typography,
} from '@mui/material'
import { DetailProps } from '../../../utilities/CRUDComponents'
import { Page, PageContent } from 'types'
import { PageElementEditorField } from './PageElementEditorField'

const selectOptions: { [key: string]: SelectDetailFieldOption[] } = {
  publishedStatus: [
    { label: 'Draft', value: 'DRAFT' },
    { label: 'Published', value: 'PUBLISHED' },
  ],
}

export function PageManagementDetail(props: DetailProps<Page>) {
  const [editMode, setEditMode] = useState<boolean>(props.isCreate)
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false)

  if (props.loading) {
    return (
      <Card>
        <CardContent>
          <CircularProgress />
        </CardContent>
      </Card>
    )
  }

  return (
    <Card>
      <Formik
        initialValues={props.item ?? ({ content: [] as PageContent } as Page)}
        onSubmit={async (values, { setSubmitting }) => {
          setSubmitting(true)
          const result = await props.onSubmit(values)
          setSubmitting(false)

          if (result) {
            setEditMode(false)
          }
        }}
        validate={async pageMetadata => {
          // Formik recommends a declarative template for validation, but since we only have a few fields inlining the logic will suffice.
          const errors: { [key: string]: string } = {}

          const mandatoryKeys: (keyof Page)[] = []

          for (const key of mandatoryKeys) {
            if (pageMetadata[key] === '' || pageMetadata[key] === undefined) {
              errors[key] = 'Field cannot be empty.'
            }
          }
          return errors
        }}
      >
        {({ isSubmitting, isValid, setValues, values, setFieldValue }) => (
          <>
            <CardActions>
              <Button onClick={props.onClose}>Close</Button>

              {!props.isCreate && (
                <Button
                  onClick={() => {
                    if (editMode && props.item) {
                      setValues(props.item)
                    }
                    setEditMode(!editMode)
                  }}
                >
                  {editMode ? 'Reset' : 'Edit'}
                </Button>
              )}

              {!props.isCreate && (
                <Button onClick={() => setShowDeleteModal(true)}>Delete</Button>
              )}
            </CardActions>
            <Divider />

            <CardContent>
              {props.detailBanner && (
                <Alert
                  severity={props.detailBanner.isSuccess ? 'success' : 'error'}
                  sx={{ marginBottom: '10px' }}
                >
                  {props.detailBanner.content}
                </Alert>
              )}
              <Typography gutterBottom variant="h5" component="div">
                {props.item?.title}
              </Typography>

              <Box>
                <Form>
                  {editMode && (
                    <TextDetailField
                      editMode={editMode}
                      label="Title"
                      name="title"
                    />
                  )}
                  <SelectDetailField
                    editMode={editMode}
                    label="Published Status"
                    name="publishedStatus"
                    options={selectOptions.publishedStatus}
                  />

                  <PageElementEditorField
                    content={values.content}
                    updateContent={(content: PageContent) =>
                      setFieldValue('content', content)
                    }
                  />

                  {!editMode && (
                    <TextDetailField
                      editMode={false}
                      label="Update Time"
                      name="dateTimeOriginal"
                    />
                  )}

                  {editMode && (
                    <Box style={{ display: 'flex', marginTop: '10px' }}>
                      <Button
                        disabled={isSubmitting || !isValid || props.loading}
                        type="submit"
                      >
                        Update
                      </Button>
                      {isSubmitting && <CircularProgress />}
                    </Box>
                  )}
                </Form>
              </Box>
            </CardContent>
            <Dialog
              open={showDeleteModal}
              onClose={() => setShowDeleteModal(false)}
              aria-labelledby="delete-page-dialog-title"
              aria-describedby="delete-page-dialog-description"
            >
              <DialogTitle id="delete-page-dialog-title">
                Delete page?
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="delete-page-dialog-description">
                  Are you sure you want to delete this page? This action is
                  permanent and cannot be undone.
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={() => setShowDeleteModal(false)}>
                  Cancel
                </Button>
                <Button
                  onClick={() => props.onDelete(props.item as Page)}
                  autoFocus
                >
                  Delete
                </Button>
              </DialogActions>
            </Dialog>
          </>
        )}
      </Formik>
    </Card>
  )
}
