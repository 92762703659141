"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.currentConfig = exports.currentStage = exports.getStages = exports.getGlobalConfig = exports.getConfig = void 0;
var beta_1 = require("./beta");
var global_1 = require("./global");
var prod_1 = require("./prod");
var getConfig = function (stage) {
    if (!stage) {
        throw new Error('Missing stage');
    }
    return stage === 'beta' ? beta_1.beta : prod_1.prod;
};
exports.getConfig = getConfig;
var getGlobalConfig = function () { return global_1.globalConfig; };
exports.getGlobalConfig = getGlobalConfig;
var getStages = function () { return ['beta', 'prod']; };
exports.getStages = getStages;
exports.currentStage = process.env.STAGE ||
    process.env.REACT_APP_STAGE ||
    'beta';
exports.currentConfig = (0, exports.getConfig)(exports.currentStage);
