import {
  Routes,
  Route,
  Link,
  Navigate,
  useNavigate,
  useLocation,
} from 'react-router-dom'
import { UploadManager } from './Upload'
import { PhotoManagement } from './management/PhotoManagement'
import { Box, Tab, Tabs, Typography } from '@mui/material'
import { useState } from 'react'
import { PageManagement } from './pages/PageManagement'

export function Admin() {
  const currentPath = useLocation().pathname.split('/').pop()

  const [tab, setTab] = useState(currentPath)
  const navigate = useNavigate()

  return (
    <section>
      <Typography variant="h4">Admin Console</Typography>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          value={tab}
          onChange={(_: React.SyntheticEvent, newValue: string) => {
            setTab(newValue)
            navigate(newValue)
          }}
          aria-label="admin tabs"
        >
          <Tab label="Manage" value="manage" />
          <Tab label="Uploads" value="upload" />
          <Tab label="Pages" value="pages" />
        </Tabs>
      </Box>
      <Routes>
        <Route path={`/upload`} element={<UploadManager />} />
        <Route path={`/manage`} element={<PhotoManagement />} />
        <Route path={`/pages`} element={<PageManagement />} />
        <Route path={`/`} element={<Navigate to="manage" />} />
      </Routes>
    </section>
  )
}
