import { Header } from './Header'
import { Gallery } from './Gallery'
import './App.css'
import { BannerProvider } from './BannerProvider'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { Admin } from './admin/Admin'

function App() {
  console.log(process.env)
  return (
    <div className="App">
      <BannerProvider>
        <Header />
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Gallery />} />

            <Route path="/admin/*" element={<Admin />} />
          </Routes>
        </BrowserRouter>
      </BannerProvider>
    </div>
  )
}

export default App
