import { useMemo } from 'react'
import { useWindowSize } from './useWindowSize'
import { Box } from '@mui/material'

export function VerticalFlexGallery<T>({
  columnWidth,
  components,
}: {
  columnWidth: number
  components: Array<JSX.Element>
}) {
  const { innerWidth } = useWindowSize()

  const columnCount = Math.floor(innerWidth ? innerWidth / columnWidth : 1)
  // Split the uploads into columns
  const columns = useMemo(() => {
    const columnArray: JSX.Element[][] = Array.from(
      { length: columnCount },
      () => []
    )
    Object.values(components).forEach((component, index) => {
      columnArray[index % columnCount].push(component)
    })
    return columnArray
  }, [components, columnCount])

  return (
    <Box
      sx={{
        display: 'flex',
      }}
    >
      {columns.map((column, index) => (
        <Box
          key={index}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            flex: 1,
            marginRight: '15px',
          }}
        >
          {column.map((component, index) => (
            <Box key={index} sx={{ marginBottom: '15px' }}>
              {component}
            </Box>
          ))}
        </Box>
      ))}
    </Box>
  )
}
