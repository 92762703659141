"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.chunkedAsyncProcessor = void 0;
var _1 = require(".");
/**
 * Utility function used to process resources asynchronously in "chunks" of customizable size with a customizable wait time in between. Given an array, will invoke the provided callback against each element. Similar to Promise.all, but with the ability to provide a chunk size and wait time to prevent overloading of a resource.
 * @param arr The array of inputs to process
 * @param cb The callback to invoke against each element of the array
 * @param chunkSize How many elements of the array are processed at a single time
 * @param waitBetweenChunksMilliseconds How long to wait in between processing of each chunk
 * @returns An array containing the outputted results of invoking the provided callback against each element
 */
function chunkedAsyncProcessor(arr, cb, chunkSize, waitBetweenChunksMilliseconds) {
    if (chunkSize === void 0) { chunkSize = 10; }
    if (waitBetweenChunksMilliseconds === void 0) { waitBetweenChunksMilliseconds = 1000; }
    return __awaiter(this, void 0, void 0, function () {
        var chunkedInput, results, _a, _b, _c, i, chunk, _d, _e, _f, _g, e_1, error, e_2_1;
        var e_2, _h;
        return __generator(this, function (_j) {
            switch (_j.label) {
                case 0:
                    if (isNaN(chunkSize) || chunkSize <= 0) {
                        throw new Error('chunkSize parameter must be a positive integer.');
                    }
                    if (isNaN(waitBetweenChunksMilliseconds) ||
                        waitBetweenChunksMilliseconds <= 0) {
                        throw new Error('waitBetweenChunksMilliseconds parameter must be a positive integer.');
                    }
                    chunkedInput = arr.reduce(function (acc, _, i) {
                        if (i % chunkSize === 0)
                            acc.push(arr.slice(i, i + chunkSize));
                        return acc;
                    }, []);
                    results = [];
                    _j.label = 1;
                case 1:
                    _j.trys.push([1, 10, 11, 12]);
                    _a = __values(chunkedInput.entries()), _b = _a.next();
                    _j.label = 2;
                case 2:
                    if (!!_b.done) return [3 /*break*/, 9];
                    _c = __read(_b.value, 2), i = _c[0], chunk = _c[1];
                    _j.label = 3;
                case 3:
                    _j.trys.push([3, 6, , 7]);
                    _e = (_d = results.push).apply;
                    _f = [results];
                    _g = [[]];
                    return [4 /*yield*/, Promise.all(chunk.map(function (input) { return cb(input); }))];
                case 4:
                    _e.apply(_d, _f.concat([__spreadArray.apply(void 0, _g.concat([__read.apply(void 0, [(_j.sent())]), false]))]));
                    return [4 /*yield*/, new Promise(function (resolve) {
                            return setTimeout(function () {
                                resolve(null);
                            }, waitBetweenChunksMilliseconds);
                        })];
                case 5:
                    _j.sent();
                    return [3 /*break*/, 7];
                case 6:
                    e_1 = _j.sent();
                    error = "Error caught in chunkedAsyncProcessor at chunk ".concat(i + 1, " of ").concat(chunkedInput.length);
                    console.error(e_1);
                    _1.logger.error(error);
                    throw new Error("".concat(error, ": ").concat(e_1.message));
                case 7:
                    _1.logger.info("Successfully processed chunk ".concat(i + 1, " of ").concat(chunkedInput.length));
                    _j.label = 8;
                case 8:
                    _b = _a.next();
                    return [3 /*break*/, 2];
                case 9: return [3 /*break*/, 12];
                case 10:
                    e_2_1 = _j.sent();
                    e_2 = { error: e_2_1 };
                    return [3 /*break*/, 12];
                case 11:
                    try {
                        if (_b && !_b.done && (_h = _a.return)) _h.call(_a);
                    }
                    finally { if (e_2) throw e_2.error; }
                    return [7 /*endfinally*/];
                case 12: return [2 /*return*/, results];
            }
        });
    });
}
exports.chunkedAsyncProcessor = chunkedAsyncProcessor;
