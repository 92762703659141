import { DataGrid, GridColDef } from '@mui/x-data-grid'
import { useMemo, useState } from 'react'
import { TableProps } from '../../../utilities/CRUDComponents'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { Page } from 'types'

const columns: GridColDef[] = [
  { field: 'entryId', headerName: 'ID', flex: 2 },
  { field: 'title', headerName: 'Title', flex: 2 },
]

export function PageManagementTable(props: TableProps<Page>) {
  const rows = useMemo(
    () =>
      props.data.map(page => ({
        ...page,
        id: page.entryId,
      })),
    [props.data]
  )
  const [selectedRows, setSelectedRows] = useState<string[]>([])
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false)

  return (
    <section style={{ height: '100%' }}>
      <Box sx={{ marginBottom: '8px' }}>
        <LoadingButton loading={props.loading} onClick={props.onRefresh}>
          Refresh
        </LoadingButton>
        <LoadingButton loading={props.loading} onClick={props.onCreate}>
          Create
        </LoadingButton>
        {selectedRows.length > 0 && (
          <LoadingButton
            onClick={() => setShowDeleteModal(true)}
            sx={{ marginLeft: '4px' }}
            loading={props.loading}
          >
            Delete
          </LoadingButton>
        )}
      </Box>

      <DataGrid
        rowHeight={75}
        onRowClick={row => props.onRowClick(row.row as Page)}
        rows={rows}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 100,
            },
          },
        }}
        pageSizeOptions={[100]}
        checkboxSelection
        disableRowSelectionOnClick
        onRowSelectionModelChange={newSelection =>
          setSelectedRows(newSelection as string[])
        }
        rowSelectionModel={selectedRows}
        sx={{
          // pointer cursor on ALL rows
          '& .MuiDataGrid-row:hover': {
            cursor: 'pointer',
          },
        }}
      />

      <Dialog
        open={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
        aria-labelledby="delete-pages-dialog-title"
        aria-describedby="delete-pages-dialog-description"
      >
        <DialogTitle id="delete-pages-dialog-title">Delete pages?</DialogTitle>
        <DialogContent>
          <DialogContentText id="delete-pages-dialog-description">
            Are you sure you want to delete the selected pages? This action is
            permanent and cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowDeleteModal(false)}>Cancel</Button>
          <Button
            onClick={() => {
              props.onDeleteRows(
                selectedRows.map(
                  row =>
                    ({
                      entryId: row,
                    } as Page)
                )
              )
              setShowDeleteModal(false)
            }}
            autoFocus
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </section>
  )
}
