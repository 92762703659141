// A page is an empty canvas and elements can be added to it.
// three elements planned, text, gallery, and triple image thing
// The right side is always kept reserved for when clicking into an element that has pages
// depending on the element clicked, the right side renders different components
// right top is a searchable table of pages with a button to add a new page
// right bottom is the rearrangement of pages within that element

import { Page } from 'types'
import { CRUDContainer } from '../../../utilities/CRUDComponents'
import {
  deletePage,
  deletePages,
  fetchAllPages,
  fetchPage,
  updatePage,
} from '../../../utilities/api'
import { PageManagementDetail } from './PageManagementDetail'
import { PageManagementTable } from './PageManagementTable'

export const PageManagement = () => {
  return (
    <CRUDContainer<Page>
      DetailComponent={PageManagementDetail}
      itemDisplayName="page"
      itemIdKey="entryId"
      listFunction={fetchAllPages}
      retrievalFunction={fetchPage}
      TableComponent={PageManagementTable}
      updateFunction={updatePage}
      deleteFunction={deletePage}
      bulkDeleteFunction={items => deletePages(items.map(item => item.entryId))}
    />
  )
}
