import { MenuItem, TextField } from '@mui/material'
import type { FieldHookConfig } from 'formik'
import { useField } from 'formik'

type DetailFieldProps = {
  label?: string
  placeholder?: string
  editMode: boolean
  enableCopy?: boolean
  multline?: boolean
} & FieldHookConfig<string | boolean>

export type TextDetailFieldProps = DetailFieldProps & {
  placeholder?: string
}
export const TextDetailField = (props: TextDetailFieldProps) => {
  const [field, meta, helper] = useField(props)

  return (
    <div>
      <TextField
        multiline={props.multline}
        fullWidth
        label={props.label}
        variant={props.editMode ? 'outlined' : 'standard'}
        error={meta.touched && Boolean(meta.error)}
        helperText={meta.touched && meta.error}
        name={field.name}
        onChange={event => {
          helper.setTouched(true)
          field.onChange(event)
        }}
        InputProps={{
          readOnly: !props.editMode,
        }}
        placeholder={props.placeholder}
        value={field.value as string}
        margin="dense"
      />
    </div>
  )
}

export type SelectDetailFieldOption = {
  label: string
  value: string
}
export type SelectDetailFieldProps = DetailFieldProps & {
  options: SelectDetailFieldOption[]
}
export const SelectDetailField = (props: SelectDetailFieldProps) => {
  const [field, meta, helper] = useField(props)

  return (
    <div>
      <TextField
        fullWidth
        select={props.editMode}
        label={props.label}
        variant={props.editMode ? 'outlined' : 'standard'}
        error={meta.touched && Boolean(meta.error)}
        helperText={meta.touched && meta.error}
        name={field.name}
        onChange={event => {
          helper.setTouched(true)
          field.onChange(event)
        }}
        InputProps={{
          readOnly: !props.editMode,
        }}
        placeholder={props.placeholder}
        value={field.value as string}
        margin="dense"
      >
        {props.options.map(option => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>
    </div>
  )
}

// export type MultiSelectDetailFieldOption = SelectDetailFieldOption
// export type MultiSelectDetailFieldProps = {
//   label?: string
//   editMode: boolean
//   options: MultiSelectDetailFieldOption[]
// } & FieldHookConfig<string>
// export const MultiSelectDetailField = ({
//   editMode,
//   label,
//   options,
//   ...props
// }: MultiSelectDetailFieldProps) => {
//   const [field, meta, helper] = useField(props)

//   const entries = useMemo(
//     () =>
//       Array.isArray(field.value) ? field.value : ([field.value] as string[]),
//     [field.value]
//   )

//   const onToggle = useCallback(
//     (values: string[]) => {
//       helper.setTouched(true)
//       helper.setValue(values.filter(t => !!t) as any)
//     },
//     [helper]
//   )

//   return (
//     <div style={{ display: 'flex', flexDirection: 'column', marginTop: '8px' }}>
//       <div style={{ display: 'flex' }}>
//         {label && (
//           <Type.Text isBold size="S">
//             {label}
//           </Type.Text>
//         )}
//       </div>

//       {editMode ? (
//         <div style={{ marginTop: '3px' }}>
//           <CheckboxList
//             onChange={e => onToggle(e.values)}
//             options={options}
//             values={entries}
//           />
//         </div>
//       ) : (
//         <div style={{ wordBreak: 'break-all' }}>
//           <Type.Text size="S">
//             {field.value ? (
//               field.value.length ? (
//                 entries.join(', ')
//               ) : (
//                 <div style={{ fontStyle: 'italic' }}>No entries</div>
//               )
//             ) : (
//               <div style={{ fontStyle: 'italic' }}>Empty field</div>
//             )}
//           </Type.Text>
//         </div>
//       )}
//       {meta.touched && meta.error && (
//         <Type.Text color={SemanticColorToken.Negative} size="S">
//           {meta.error}
//         </Type.Text>
//       )}
//     </div>
//   )
// }

// export type TextAreaFieldProps = TextDetailFieldProps
// export const TextAreaDetailField = (props: TextAreaFieldProps) => {
//   const [field, , helper] = useField(props)

//   return (
//     <DetailField {...props}>
//       <TextArea
//         aria-label={props.label}
//         initialNumLines={8}
//         isResizable
//         onChange={({ event }) => {
//           helper.setTouched(true)
//           helper.setValue(event.target.value)
//         }}
//         placeholder={props.placeholder}
//         value={field.value as string}
//       />
//     </DetailField>
//   )
// }

// export type TextMultiEntryFieldProps = {
//   label?: string
//   editMode: boolean
// } & FieldHookConfig<string>
// export const TextMultiEntryField = ({
//   editMode,
//   label,
//   ...props
// }: TextMultiEntryFieldProps) => {
//   const [field, meta, helper] = useField(props)

//   const entries = useMemo(
//     () =>
//       Array.isArray(field.value) ? field.value : ([field.value] as string[]),
//     [field.value]
//   )

//   const entryOnChange = useCallback(
//     (index: number, value: string) => {
//       const update = [...entries]
//       update[index] = value

//       helper.setTouched(true)
//       helper.setValue(update as any)
//     },
//     [entries, helper]
//   )
//   const deleteEntry = useCallback(
//     (index: number) => {
//       const update = [...entries]
//       update.splice(index, 1)

//       helper.setTouched(true)
//       helper.setValue(update as any)
//     },
//     [entries, helper]
//   )
//   const addEntry = useCallback(() => {
//     helper.setTouched(true)
//     helper.setValue([...entries, ''] as any)
//   }, [entries, helper])

//   return (
//     <div style={{ display: 'flex', flexDirection: 'column', marginTop: '8px' }}>
//       <div style={{ display: 'flex' }}>
//         {label && (
//           <Type.Text isBold size="S">
//             {label}
//           </Type.Text>
//         )}
//       </div>

//       {editMode ? (
//         <div style={{ marginTop: '3px' }}>
//           {entries.map((entry, i) => (
//             <div key={i} style={{ display: 'flex', marginBottom: '5px' }}>
//               <TextInput
//                 onChange={e => {
//                   entryOnChange(i, e.value)
//                 }}
//                 overrideRootStyles={{ marginRight: '5px' }}
//                 value={entry}
//               />
//               <Button
//                 backgroundColor={SemanticColorToken.Error}
//                 label="Delete Entry"
//                 onClick={() => deleteEntry(i)}
//                 size="compact"
//                 type="button"
//               />
//             </div>
//           ))}
//           <TextButton
//             color={SemanticColorToken.Positive}
//             label="Add Entry"
//             onClick={addEntry}
//             type="button"
//             variant="standalone"
//           />
//         </div>
//       ) : (
//         <div style={{ wordBreak: 'break-all' }}>
//           <Type.Text size="S">
//             {field.value ? (
//               field.value.length ? (
//                 entries.join(', ')
//               ) : (
//                 <div style={{ fontStyle: 'italic' }}>No entries</div>
//               )
//             ) : (
//               <div style={{ fontStyle: 'italic' }}>Empty field</div>
//             )}
//           </Type.Text>
//         </div>
//       )}
//       {meta.touched && meta.error && (
//         <Type.Text color={SemanticColorToken.Negative} size="S">
//           {meta.error}
//         </Type.Text>
//       )}
//     </div>
//   )
// }

// export type DateDetailFieldProps = TextDetailFieldProps
// export const DateDetailField = ({
//   editMode,
//   label,
//   ...props
// }: DateDetailFieldProps) => {
//   const [field, meta, helper] = useField(props)
//   const [invalidDate, setInvalidDate] = React.useState(false)

//   const formattedDate = useMemo(() => {
//     if (!field.value) {
//       return ''
//     }
//     // convert field.value which is a unixtimestamp in second to ISO string
//     const date = new Date((field.value as any) * 1000)
//     return date.toISOString()
//   }, [field.value])

//   const onChange = useCallback(
//     (isoString: string) => {
//       const date = new Date(isoString)

//       const isValid = !isNaN(date.getTime())

//       if (isValid) {
//         helper.setValue((date.getTime() / 1000) as any)
//         setInvalidDate(false)
//       } else {
//         setInvalidDate(true)
//       }
//     },
//     [helper]
//   )

//   return (
//     <div style={{ display: 'flex', flexDirection: 'column', marginTop: '8px' }}>
//       <div style={{ display: 'flex' }}>
//         {label && (
//           <Type.Text isBold size="S">
//             {label}
//           </Type.Text>
//         )}
//       </div>

//       {editMode ? (
//         <div style={{ marginTop: '3px' }}>
//           <DateTimeInput onChange={onChange} value={formattedDate} />
//         </div>
//       ) : (
//         <div style={{ wordBreak: 'break-all' }}>
//           <Type.Text size="S">{formattedDate}</Type.Text>
//         </div>
//       )}
//       {invalidDate && (
//         <Type.Text color={SemanticColorToken.Negative} size="S">
//           Invalid date
//         </Type.Text>
//       )}
//       {meta.error && (
//         <Type.Text color={SemanticColorToken.Negative} size="S">
//           {meta.error}
//         </Type.Text>
//       )}
//     </div>
//   )
// }
